export const Urls = {
    BaseURL: 'cdn-base-url',
    Domain: 'https://account-stg.pplfirst.com',
    FmsURL: 'https://fms-stg.pplfirst.com',

    // Client apps
    ppl_participant_connect_url: '/s4s/CustomPage/Index/35',
    ppl_provider_connect_url: '/s4s/CustomPage/Index/34',

    // Client apps
    ppl_participant_connect_url: '/s4s/CustomPage/Index/35',
    ppl_provider_connect_url: '/s4s/CustomPage/Index/34',

    // Organizations
    org_TennCare_id: 'ce287513-4667-463c-89ec-ac17002bfac5',

    // Programs
    program_virginia: 'CCC +',
    program_tenncare_id: 'd16e5fa2-a316-47f7-89bb-aeb5007dc460',
    program_tenncare_ac_id: 'b4c04c92-458a-4d7d-ad56-aeb5007c6394',
    program_tenncare_ag_id: 'b8914801-503f-4cd1-b155-aeb5007d5459',
    program_tenncare_ecf_id: '3a956c36-9d19-4161-8e48-ae98010c86e3',
    program_tenncare_kb_id: '8d5db84e-a9b9-481b-afd7-ac0c00786eae',
    program_md_dda_id: '01c5ef03-01cf-4c50-aaa5-aeed00e31980',

    // Roles
    role_stateAdmin_id: '4fa0aee3-ff4b-4940-b914-3c0bb36380e2',

    // Page ids
    page_myProviders: '2',
    page_myPrograms: '3',
    page_myDesignatedRep: '4',
    page_mySignatures: '5',
    page_addDesignatedRep: '6',
    page_addProvider: '7',
    page_sagDashboard: '8',
    page_myDetails: '9',
    page_myParticipants: '15',
    page_caseManagerDashboard: '16',
    page_servicesPayrates: '20',
    page_contactUs: '22',
    page_pplConnect: '23',
    page_budgetManagement: '26',
    page_myCourses: '27',
    page_inviteToEnroll: '28',
    page_directProviderReg: '29',
    page_agencyDashboard: '31',
    page_agencyEnrollment: '32',
    page_myRepAgreement: '34',
    page_barCodePage: '38',
    page_pplConnectCreate: '40',
    page_pplConnectMessages: '41',
    page_sagCreateEnrollment: '42',
    page_agencyPaymentDetails: '43',
    page_hireProviderAdmin: '45',
    page_purchaseRequest: '47',

    waivertype_id: '2d666d26-8ade-4d3a-bb82-c1dd3cc59abd',
    waivertype_lah: '7e5bd59d-7f37-4e69-81f5-e2a08c7eb843',
    waivertype_cwp: 'fd17a7cc-679a-433e-95bf-31d55b0f19ce',
    
    perchaseRequestFormId_OhioRise: '62c8299404d63b0001029e0f',

    // Tenncare VSHP referral form
    referral_ten_confirm: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_ten_vshp_value: '5e4e9176-b560-4593-b532-0ac2582038d6',

    // Tenncare AC referral form
    referral_confirmPgm_ten_ac_quesId: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_confimPgm_ten_ac_dropDownId: '5e4e9176-b560-4593-b532-0ac2582038d6',
    referral_mCO_ten_ac_quesId: '2e41efda-3a50-43e3-bcc2-5ee57aec5bc4',

    // Tenncare ECF referral form
    referral_confirmPgm_ten_ecf_quesId: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_confirmPgm_ten_ecf_dropDownId: '5e4e9176-b560-4593-b532-0ac2582038d6',    

    // Tenncare KB referral form
    referral_confirmPgm_ten_kb_quesId: 'ea409114-6039-4041-8012-700ee337ca78',
    referral_confirmPgm_ten_kb_dropDownId: '7bbb0d4f-3bb3-472d-8978-0d2437292be0',
    
    // Tenncare ECF provider referral form
    prReferral_pgmSelection_ten_ecf_quesId: '4d605724-0f01-49a6-9ded-4acf99217cca',
    prReferral_pgmSelection_ten_ecf_dropDown: '1446b565-0dc2-4357-bc6b-2ae90555b93f',
    
    // Tenncare KB provider referral form
    prReferral_pgmSelection_ten_kb_quesId: '4d605724-0f01-49a6-9ded-4acf99217cca',
    prReferral_pgmSelection_ten_kb_dropDown: '363a4588-a8f8-4515-9950-1718738cd6ae',

    // MD DDA provider referral form
    prReferral_waiverSelection_md_dda_quesId: 'aa44842d-ddac-4d9a-8505-946f2a43523a',
    prReferral_waiverSelection_md_dda_dropDown_cpw: '0804d3b5-4a61-4e0a-9e4e-501fe923a7fd',
    prReferral_waiverSelection_md_dda_dropDown_csw: '0c806a30-8ed6-41de-898d-daedef874ec4',
    prReferral_waiverSelection_md_dda_dropDown_fsw: 'b6930f19-be93-45ce-a4df-a6a56852ec51',
    // Ohio Rise MCO
    ohio_rise_mco: 'f5b426ca-fa44-452c-98c3-ae98009e8848',

    // TN AC MCO
    tn_ac_mco: 'ebedcdc6-8832-4593-98c1-aec10096636a',

    // PPL Connect
    pplConnect_approved: '4ce13d86-df3b-49a5-bdb0-ad040066dec9',

    // Time of Day
    connect_day: '868EE8E0-7A44-4AE6-ABE4-AF0A00A3B9A9',
    connect_night: 'BAD91EB3-6D97-479C-9D3A-AF0A00A3B9A9',

    // Availability
    connect_Monday: 'AA126533-A934-4926-9ECD-AC710041366B',
    connect_Tuesday: 'C030FD89-79B3-4547-B042-AC710041366B',
    connect_Wednesday: '9CE85371-CBB7-40E5-B910-AC710041366B',
    connect_Thursday: '48617522-AC1F-442E-A383-AC710041366B',
    connect_Friday: 'D12F12A2-4D4E-42C3-BD81-AC710041366B',
    connect_Saturday: '5D020D26-AB46-4067-81AE-AC710041366B',
    connect_Sunday: '661D02CA-1340-40AB-B3F5-AC710041366B',

    // Professional Licenses
    prof_licence_RN: '42A2F5EC-D589-4E92-907C-AC71003FDFD5',
    prof_licence_LPN: '06FFB8B3-6649-42D3-A9E8-AC71003FDFD5',
    prof_licence_SpeechTherapy: '6AAEBFCF-84B7-4E24-A796-AC71003FDFD5',
    prof_licence_PhysicalTherapy: 'A71648F3-0940-4902-A622-AC71003FDFD5',
    prof_licence_CNA: 'C074C3AA-4D51-4F93-94D1-AC71003FDFD5',
    prof_licence_Other: '98EE2719-AFE7-4F8B-A2E2-AC71003FDFD5',
    prof_licence_OccupationalTherapy: 'A9C31F6C-A5BB-4116-AACF-AC71003FDFD5',

    // Care preferences
    care_pref_NonSmoker: 'C01E4B67-A43B-49E5-8363-AC710040160E',
    care_pref_ComfortableWithChildren: 'C2D8C6C6-87D7-4C7C-ADC8-AC710040160E',
    care_pref_ComfortableWithPets: '826F6F02-C81F-47E1-B804-AC710040160E',
    care_pref_SmokeFree: 'F6142023-C781-46FE-BE57-AC710040160E',
    care_pref_Transportation: 'ECD3E4F2-026E-440C-9B94-AC710040160E',

    // Services
    services_ADL_Continence: '18DAABE3-B827-4D6B-B8DD-AC710042B0C9',
    services_IADL_ManagingMedication: '41D2C067-E135-415C-88FE-AC710042B0C9',
    services_IADL_Housework: '532D50D2-A238-4EAE-A16B-AC710042B0C9',
    services_IADL_ManagingMoney: '49CB68E0-8453-4B44-B4F4-AC710042B0C9',
    services_ADL_Ambulations: '81EA4BB0-D521-4C0A-BB1D-AC710042B0C9',
    services_ADL_PersonalHygiene: '92C67912-0DB3-4ADD-A8D2-AC710042B0C9',
    services_ADL_Toileting: '6845D99E-5872-4666-A89A-AC710042B0C9',
    services_IADL_MealPreparation: 'B53CAEEA-76AE-4416-818A-AC710042B0C9',
    services_IADL_ManagingCommunication: '46DE1F76-2F38-4E0E-91CA-AC710042B0C9',
    services_IADL_Transportation: 'E8C74D55-DF89-47A9-AE1B-AC710042B0C9',
    services_ADL_Dressing: 'AACBC3B0-0D9C-45D2-8D26-AC710042B0C9',
    services_ADL_Feeding: '7E420739-8ACF-459A-BDCF-AC710042B0C9',
    services_IADL_Shopping: '4D4860E4-493A-4470-923E-AC710042B0C9',

    // Conversational languages
    convLang_Somali: '3189515E-197E-4174-82E5-AC710040D437',
    convLang_Punjabi: '67FF0F0F-D201-45FB-B87B-AC710040D437',
    convLang_Italian: '6544AE22-67C1-4422-A864-AC710040D437',
    convLang_Vietnamese: '4DF63BE4-9208-460D-BD75-AC710040D437',
    convLang_Cambodian: '5FB240CF-D7A5-4F47-8150-AC710040D437',
    convLang_German: '0854C796-F9EB-4BB5-BFC1-AC710040D437',
    convLang_Cantonese: 'AB2BA54F-60D9-48B6-AB00-AC710040D437',
    convLang_Amhari: 'AF3E18F6-9EA0-4F2D-86E4-AC710040D437',
    convLang_Khmer: 'E14D7336-B835-456D-909F-AC710040D437',
    convLang_Creolen: '4E1971DB-DA15-44F3-9F6C-AC710040D437',
    convLang_Mandarin: '8FB0734E-62A9-487A-A9C9-AC710040D437',
    convLang_Arabic: '0507B7A0-720D-48AD-95FB-AC710040D437',
    convLang_Haitian: '7738E340-2A40-41F2-861D-AC710040D437',
    convLang_English: '9638C40B-3E0A-4749-AD0E-AC710040D437',
    convLang_Chinese: '7B3E4673-96DC-455E-AAD1-AC710040D437',
    convLang_Portuguese: '9C1A1BCE-7518-44A4-910E-AC710040D437',
    convLang_French: 'C8B2AD5A-93BA-49B4-B13A-AC710040D437',
    convLang_Tagalog: '2E74DB8B-664A-4FCB-AF96-AC710040D437',
    convLang_Russian: '5F7A666C-5C95-4077-AA46-AC710040D437',
    convLang_Farsi: '9C76821C-DC0F-4254-8D0B-AC710040D437',
    convLang_Korean: '31014831-653B-4379-9DD2-AC710040D437',
    convLang_Spanish: 'C9EE4067-2185-44A7-9568-AC710040D437',
    convLang_Japanese: '1AE1F409-BC08-42D8-AFB2-AC710040D437',

    // Allergies
    allergies_none: '84EE8E25-8BF7-4DE2-8246-AC710041A01C',
    allergies_Grass: '58AF6ADF-6B16-4668-8E97-AC710041A01C',
    allergies_Dust: 'ECC7AA64-997C-4AD1-8128-AC710041A01C',
    allergies_Other: 'D42EE526-A1AA-41C8-8487-AC710041A01C',
    allergies_Fish: '90AFA2D4-5564-4AC3-95A8-AC710041A01C',
    allergies_Materials: 'D824052B-4CC3-4853-A22F-AC710041A01C',
    allergies_Food: 'B96827FB-85A5-4199-A2B9-AC710041A01C',
    allergies_Medicine: 'DB3326B1-C6E5-4A76-AA69-AC710041A01C',
    allergies_Fabric: '1E6BE88F-BAF7-42BE-82C9-AC710041A01C',
    allergies_Rodents: '1E9998F2-BD17-4035-B6EF-AC710041A01C',
    allergies_Rabbits: 'E0FBFEB4-C5CA-43E1-826D-AC710041A01C',
    allergies_Horses: '597D1151-A77D-4621-81BA-AC710041A01C',
    allergies_Reptiles: 'CC07EC65-5386-4B58-ADBB-AC710041A01C',
    allergies_Cats: '2D3B374B-1D14-4D68-B886-AC710041A01C',
    allergies_Birds: 'D52E4456-5E41-44F8-9430-AC710041A01C',
    allergies_Dogs: '6486F768-58AC-4631-8298-AC710041A01C',

    // CBC question
    cbc_questtion_id: 'f457bca7-4b32-44af-84c2-e2a81b72c103',
    cbc_pass: '8cc05890-9a03-4dfa-90b8-a8c0236a2c84',
    cbc_exceptiongranted: '91ef1da4-4f64-4640-8dc9-774c29f26c15',
    cbc_hasAOR: '13b951df-0986-4605-8bfb-7a95d26001e7',

    // Is the participant under the age of 18?
    checklist_underEighteen_yes: '12771be4-9325-48a1-8c9f-8421f3f93c4b',
    checklist_underEighteen_no: '176d0145-590b-4886-9ef4-16bde8cf9f24',

    // DSS Check Status
    checklist_dss_inprogress: 'c05dd574-4ffe-4520-972c-8f96b39bb34f',
    checklist_dss_pass: 'd1d157a3-d8f1-47c1-844e-a6151839b174',
    checklist_dss_fail: '64d32267-b69d-45e7-b030-bbfa04bfbf0e',

    // Direct provider redirect url
    dirRedirectUrl: '/s4s/'
}