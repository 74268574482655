import {Urls} from '../constants/'
import 'styles/z-clientAppsConnectDashboard.scss'

(function(){

    /* global $ */
    'use strict';
    
    var Init = {
        init: function() {
            this.adjustDom();
            this.bindUIActions();
        },
    
        adjustDom: function() {
    
            if ($.sc.authSettings.pplRole === '22a21fd3-9b01-4c07-a8a9-e70d17fbb4da') {
                
                
                $('.js-hide-dashboard').html(
                    `
    
                        <div class="pcg-homepage-jumbotron js-homepage-jumbotron"> 
                            <h1 class="pcg-homepage-jumbotron__title">Administrator dashboard</h1> 
                            <p>Welcome to PPL Provider Connect, as an administrator you can do the following:</p>
                            <ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.publicpartnerships.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">
                                <li class="pcg-homepage-jumbotron__item"><div class="pcg-rte-wrapper">Manage providers' profile listings</div></li>
                                <li class="pcg-homepage-jumbotron__item"><div class="pcg-rte-wrapper">Work as other users</div></li>
                            </ul>
                        </div>
    
                        <div class="container mar-top1"> 
                            <section class=""> 
                                <article class=""> 
    
                                    <div class="steps-list-container"> 
                                        <div class="dbCards continue-card">
                                            <a href="/s4s/MyProfile/MyProfileTab">
                                                <h1>My details</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
    
                                        <div class="dbCards continue-card">
                                            <a href="/s4s/EventsAdministration">
                                                <h1>Manage provider profile listings</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
    
                                        <div class="dbCards continue-card">
                                            <a href="/s4s/UserManagement/Users?orgId=af2cb366-9be1-4563-aa04-aecb00995a6c">
                                                <h1>Work as users</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
                                    </div>
                                </article> 
                            </section> 
                        </div>
                    `
                    // <h2 class="dashboard-sub-headers">User management</h2>
                    //     <div class="steps-list-container">
                                // <div class="dbCards continue-card">
                                //     <a href="/s4s/CmsContexts/Index/b3c65e8b-00ec-449c-9ecf-6a7ca4293953">
                                //         <h1>Content Management</h1>
                                //         <span class="card-btn">View</span>
                                //     </a>
                                // </div>
                    //         <div class="dbCards continue-card">
                    //             <a href="/s4s/UserManagement/Users?orgId=af2cb366-9be1-4563-aa04-aecb00995a6c">
                    //                 <h1>Manage User</h1>
                    //                 <span class="card-btn">View</span>
                    //             </a>
                    //         </div>
                    //         <div class="dbCards continue-card">
                    //             <a href="/s4s/AccountDetailsAdministration/AccountDetails">
                    //                 <h1>Business Organisations</h1>
                    //                 <span class="card-btn">View</span>
                    //             </a>
                    //         </div>
                    //         <div class="dbCards continue-card">
                    //             <a href="/s4s/GroupsAdministration/Groups">
                    //                 <h1>Form Management</h1>
                    //                 <span class="card-btn">View</span>
                    //             </a>
                    //         </div>
                    //         <div class="dbCards continue-card">
                    //             <a href="/s4s/FormsAdministration">
                    //                 <h1>Group Management</h1>
                    //                 <span class="card-btn">View</span>
                    //             </a>
                    //         </div>
                    //     </div>
    
                    //     <h2 class="dashboard-sub-headers">Other</h2>
                    //     <div class="steps-list-container">
                    //         <div class="dbCards continue-card">
                    //             <a href="/s4s/MyProfile/DocumentsTab">
                    //                 <h1>Access Downloaded Forms</h1>
                    //                 <span class="card-btn">View</span>
                    //             </a>
                    //         </div>
    
                    //     </div> 
                )
                
            
            } else if ($.sc.authSettings.pplRole === '112c3cc7-2c81-4702-a988-ec2c1bb03cb9') {
                
                $('.js-hide-dashboard').html(
                    `
                        <div class="pcg-homepage-jumbotron js-homepage-jumbotron"> 
                            <h1 class="pcg-homepage-jumbotron__title">Provider dashboard</h1> 
                            <p>Welcome to PPL Provider Connect, as provider you can do the following:</p>
                            <ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.publicpartnerships.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">
                                <li class="pcg-homepage-jumbotron__item"><div class="pcg-rte-wrapper">Manage your profile listing</div></li>
                                <li class="pcg-homepage-jumbotron__item"><div class="pcg-rte-wrapper">Update your account details</div></li>
                            </ul>
                        </div>
    
                        <div class="container mar-top1"> 
                            <section class=""> 
                                <article class=""> 
    
                                    <div class="steps-list-container"> 
                                        <div class="dbCards continue-card">
                                            <a href="/s4s/MyProfile/MyProfileTab">
                                                <h1>My details</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
                                        <div class="dbCards continue-card">
                                            <a href="/s4s/EventsAdministration/Events">
                                                <h1>Manage your profile listing</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
                                        <div class="dbCards continue-card">
                                            <a href="${Urls.ppl_participant_connect_url}?messages=true">
                                                <h1>My messages</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
                                    </div>
    
                                </article> 
                            </section> 
                        </div>
    
                    `
                );
    
            } else if ($.sc.authSettings.pplRole === '9b83f050-47e7-44c6-b535-468fcca8dd42') {
                
    
                // fetch('/s4s/api/Event/GetEventsJson?orgId=&search=&attrValue=&templateId=333a5681-edad-4909-bdfb-aede00780274', { 
                //     method: 'GET'
                // })
                // .then(function(response) { return response.json(); })
                // .then(function(json) {
    
                //     const thisLength = json.Result.length;
                //     let directManageTile = '';
    
                //     if ( thisLength === 0) {
                //         directManageTile = `
                //             <div class="dbCards continue-card">
                //                 <a href="/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=333a5681-edad-4909-bdfb-aede00780274">
                //                     <h1>Directory Management</h1>
                //                     <span class="card-btn">Create listing</span>
                //                 </a>
                //             </div>
                //         `
                //     } else {
                //         directManageTile = `
                //             <div class="dbCards continue-card">
                //                 <a href="/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=333a5681-edad-4909-bdfb-aede00780274">
                //                     <h1>Directory Management</h1>
                //                     <span class="card-btn">Edit listing</span>
                //                 </a>
                //             </div>
                //         `
                //     }
                // });
                
                $('.js-hide-dashboard').html(
                    `
                        <div class="pcg-homepage-jumbotron js-homepage-jumbotron"> 
                            <h1 class="pcg-homepage-jumbotron__title">Participant dashboard</h1> 
                            <p>Welcome to PPL Provider Connect, as paticipant you can do the following:</p>
                            <ul class="pcg-homepage-jumbotron__list" style="list-style-image: url(https://www.publicpartnerships.com/media/f30g424t/map-icon_blueberry_map-icon.png?mode=max&amp;width=20&amp;upscale=false&amp;rnd=132320507198400000);">
                                <li class="pcg-homepage-jumbotron__item"><div class="pcg-rte-wrapper">Search and contact providers</div></li>
                                <li class="pcg-homepage-jumbotron__item"><div class="pcg-rte-wrapper">Update your account settings</div></li>
                            </ul>
                        </div>
    
                        <div class="container mar-top1"> 
                            <section class=""> 
                                <article class=""> 
    
                                    <div class="steps-list-container"> 
                                        <div class="dbCards continue-card">
                                            <a href="/s4s/MyProfile/MyProfileTab">
                                                <h1>My details</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
                                        <div class="dbCards continue-card">
                                            <a href="/s4s/CustomPage/Index/34">
                                                <h1>Find a provider</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
                                        <div class="dbCards continue-card">
                                            <a href="${Urls.ppl_participant_connect_url}?messages=true">
                                                <h1>My messages</h1>
                                                <span class="card-btn">View</span>
                                            </a>
                                        </div>
                                    </div>
    
                                </article> 
                            </section> 
                        </div>
                    `
                )
            }
    
        },
    
        bindUIActions: function() {
            
        },
    
        unload: function() {
    
        }
    };
    
    Init.init();
})();